import React, { useCallback, useContext, useState } from "react";
import {
  _get_admin_time_zone_localStorage,
  _get_consultant_from_localStorage,
  _get_user_from_localStorage,
} from "../DAL/localstorage/LocalStorage";
import { baseUri } from "src/config/config";
import io from "socket.io-client";

const CreateContentSetting = React.createContext();
const get_user = _get_user_from_localStorage();
const get_consultant = _get_consultant_from_localStorage();

export const useContentSetting = () => useContext(CreateContentSetting);

let socketUrl = "https://apidev.gymrite.ie";

let socket = {};
socket = io(socketUrl + `?user_id=${get_user._id}&role=delegate`);

export function ContentSettingState({ children }) {
  /* ------------------------------------------------------
  ------------------| States |--------------------
  ------------------------------------------------------- */

  const [adminTimeZone, setAdminTimeZone] = useState(
    _get_admin_time_zone_localStorage
  );

  //console.log(adminTimeZone, "adminTimeZone1");
  // console.log(
  //   get_consultant,
  //   "get_consultantget_consultantget_consultantget_consultantget_consultant"
  // );
  const [userInfo, setUserInfo] = useState(get_user);
  const [consultantInfo, setConsultantInfo] = useState(get_consultant);
  const [userExerciseHistory, setUserExerciseHistory] = useState([]);
  const [unreadMessageCount, setUnreadMessageCount] = useState(0);
  const [userExerciseHistoryLoading, setUserExerciseHistoryLoading] =
    useState(false);
  const setUserExerciseHistoryCallback = useCallback((val) => {
    setUserExerciseHistory(val);
  }, []);
  const [selectedExercise, setSelectedExercise] = useState("");
  const [googleClientId, setGoogleClientId] = useState("");
  const [tinymceKey, setTinymceKey] = useState("");
  const [programTab, setProgramTab] = useState("");

  // const [contentSettingData, setContentSettingData] = useState(content_setting);
  // const [projectInfo, setProjectInfo] = useState(get_project_info);
  // const [dashboardEventData, setDashboardEventData] = useState(
  //   get_home_event_localStorage
  // );
  // const [dashboardTestimonialData, setDashboardTestimonialData] = useState(
  //   get_testmonial_localStorage
  // );
  // const [dashboardSettingData, setDashboardSettingData] = useState(
  //   client_dashboard_setting
  // );

  /* ------------------------------------------------------
------------------/ Hooks Functions /-------------------
  ------------------------------------------------------- */

  // const handleContentSettingData = (val) => {
  //   setContentSettingData(val);
  // };
  // const handleDashboardSettingData = (val) => {
  //   setDashboardSettingData(val);
  // };
  // const handleDashboardTestimonialData = (val) => {
  //   setDashboardTestimonialData(val);
  // };
  // const handleDashboardEventData = (val) => {
  //   setDashboardEventData(val);
  // };

  const handleAdminTimeZone = (val) => {
    setAdminTimeZone(val);
  };

  const handlesetTinyKey = (val) => {
    setTinymceKey(val);
  };

  const handleGoogleId = (val) => {
    setGoogleClientId(val);
  };

  const handleUnreadMessageCount = (val) => {
    setUnreadMessageCount(val);
  };

  const handleSetUserInfo = (val) => {
    setUserInfo(val);
  };
  const handleConsultantInfo = (val) => {
    setConsultantInfo(val);
  };

  const START_SOCKET_FORCEFULLY = (user_id) => {
    socket = io(socketUrl + `?user_id=${user_id}&role=delegate`);
  };

  const STOP_SOCKET_FORCEFULLY = () => {
    socket = io(socketUrl + `?user_id=${userInfo._id}&role=delegate`);
    socket.disconnect();
  };

  //Getting Project Info from API
  // const getProjectInfo = async () => {
  //   const result = "";
  //   if (result.code === 200) {
  //     localStorage.setItem(
  //       `project_info`,
  //       JSON.stringify(result.member_setting)
  //     );
  //     setProjectInfo(result.member_setting);
  //   }
  // };

  //console.log(adminTimeZone, "adminTimeZoneadminTimeZone");
  const collection = {
    adminTimeZone,
    setAdminTimeZone,
    // projectInfo,
    // dashboardEventData,
    // contentSettingData,
    // dashboardSettingData,
    // dashboardTestimonialData,
    // handleContentSettingData,
    // handleDashboardSettingData,
    // handleDashboardTestimonialData,
    handleSetUserInfo,
    userInfo,
    setUserInfo,
    consultantInfo,
    handleConsultantInfo,
    handleAdminTimeZone,
    userExerciseHistory,
    userExerciseHistoryLoading,
    setUserExerciseHistoryLoading,
    selectedExercise,
    setSelectedExercise,
    setUserExerciseHistory: setUserExerciseHistoryCallback,
    START_SOCKET_FORCEFULLY,
    STOP_SOCKET_FORCEFULLY,
    socket,
    handleUnreadMessageCount,
    unreadMessageCount,
    handleGoogleId,
    googleClientId,
    handlesetTinyKey,
    tinymceKey,
    programTab,
    setProgramTab,
  };

  // useEffect(() => {
  //   getProjectInfo();
  // }, []);
  //console.log(consultantInfo, "consultantInfo");
  return (
    <CreateContentSetting.Provider value={collection}>
      {children}
    </CreateContentSetting.Provider>
  );
}
